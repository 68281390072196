import auth from '@itau-loans-www/shopping/src/services/http/auth'
import { defaultLeadsApiCall } from '@itau-loans-www/shopping/src/services/http/utils'
import { formatters } from 'utils'

const getLeadDocument = async () => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'document',
    method: 'get'
  })

  return data
}

const putLeadDocument = async (requestBody) => {
  const formatedBody = {
    document_type: requestBody.document_type,
    document_number: requestBody.document_number,
    document_issuer: requestBody.document_issuer,
    document_issuer_id: requestBody.document_issuer_id,
    document_issue_date: formatters.date.toISO(requestBody.document_issue_date),
    document_state: requestBody.document_state,
    document_state_id: requestBody.document_state_id
  }

  const { data } = await defaultLeadsApiCall({
    endpoint: 'document',
    method: 'put',
    body: formatedBody
  })

  return data
}

const getIdentityAgencies = async () => {
  const { data } = await auth({}).then((_http) =>
    _http.get(`v2/itau-360-shopping-api/identity-agencies/`)
  )

  return data
}

export default { getLeadDocument, putLeadDocument, getIdentityAgencies }
