import { useForm } from 'react-hook-form'

import {
  Navigation,
  Input,
  Select,
  Shimmer
} from '@itau-loans-www/shopping/src/components'
import { useProposal, useLead } from '@itau-loans-www/shopping/src/hooks'
import ProposalLayout from '@itau-loans-www/shopping/src/layouts/Proposal'
import { cleanAccents } from 'utils'

import useDocumentation from './hooks'

const Documentation = ({ pageContext }) => {
  const proposalContext = useProposal()
  const { product } = proposalContext
  const hasWppBtn = product?.proposal?.productCode !== 'FGTS'
  const {
    onSubmit,
    submitLoading,
    statesOptions,
    agenciesOptions,
    getLoading
  } = useDocumentation()

  const {
    leadData: { document_data }
  } = useLead()

  const initialValues = {
    state: document_data?.state ?? '',
    document_type: 'RG',
    document_number: document_data?.document_number ?? '',
    document_issuer: document_data?.document_issuer ?? '',
    document_issuer_id: document_data?.document_issuer_id ?? '',
    document_state: document_data?.document_state ?? '',
    document_state_id: document_data?.document_state_id ?? '',
    document_issue_date: document_data?.document_issue_date ?? ''
  }

  const {
    handleSubmit,
    control,
    formState: { isValid },
    ...formProps
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  return (
    <ProposalLayout maxSteps={4} pageContext={pageContext}>
      <div className="formalization">
        <Shimmer
          isVisible={getLoading}
          height="fit-content"
          margin="0 0 20px 0"
        >
          <h1 className="formalization__title">
            Informe seu documento de identidade
          </h1>
        </Shimmer>

        <form
          className="formalization-documentation__form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="formalization__form-fields">
            <div className="formalization__input-wrapper--full">
              <Shimmer isVisible={getLoading} height="90%" margin="0 0 20px 0">
                <Input.Text
                  label="RG"
                  id="document_number"
                  name="document_number"
                  maxLength="14"
                  autoComplete="on"
                  control={control}
                />
              </Shimmer>
            </div>
            <div className="formalization__input-wrapper--big">
              <Shimmer isVisible={getLoading} height="90%" margin="0 0 20px 0">
                <Select
                  label="Órgão expedidor"
                  id="document_issuer"
                  name="document_issuer"
                  disabled={getLoading}
                  control={control}
                  autocomplete="off"
                  options={agenciesOptions}
                  formProps={formProps}
                  filterOptions={({ options, search }) =>
                    options.filter((option) =>
                      cleanAccents(option.label)
                        .toLowerCase()
                        .includes(cleanAccents(search).toLowerCase())
                    )
                  }
                  onChange={(selectedOption) => {
                    formProps.setValue(
                      'document_issuer_id',
                      selectedOption.value
                    )
                  }}
                />
              </Shimmer>
            </div>
            <div className="formalization__input-wrapper--small">
              <Shimmer isVisible={getLoading} height="90%" margin="0 0 20px 0">
                <Select
                  label="Estado"
                  name="state"
                  control={control}
                  autocomplete="off"
                  options={statesOptions}
                  disabled={getLoading}
                  formProps={formProps}
                  filterOptions={({ options, search }) =>
                    options.filter((option) =>
                      cleanAccents(option.label)
                        .toLowerCase()
                        .includes(cleanAccents(search).toLowerCase())
                    )
                  }
                  onChange={(selectedOption) => {
                    formProps.setValue(
                      'document_state_id',
                      selectedOption.value
                    )
                    formProps.setValue(
                      'document_state',
                      selectedOption.state_uf
                    )
                  }}
                />
              </Shimmer>
            </div>

            <div className="formalization__input-wrapper--full ">
              <Shimmer isVisible={getLoading} height="90%" margin="0 0 20px 0">
                <Input.Birthdate
                  autoComplete="on"
                  control={control}
                  id="document_issue_date"
                  name="document_issue_date"
                  label="Data de emissão"
                  validators={{
                    rules: {
                      validate: {
                        lessThan1901: (value) => {
                          return Number(value.split('/')[2]) > 1901
                        }
                      }
                    },
                    messages: {
                      lessThan1901: 'o ano deve ser maior que 1901'
                    }
                  }}
                />
              </Shimmer>
            </div>
          </div>
          <Shimmer isVisible={getLoading} height="90%" margin="0 0 20px 0">
            <Navigation
              nextButtonType="submit"
              hasWppBtn={hasWppBtn}
              nextStep
              isNextStepButtonLoading={submitLoading}
              isNextButtonDisabled={!isValid}
              nextButtonId={'formalization-documentation-next-btn'}
              nextButtonText="Continuar proposta"
            />
          </Shimmer>
        </form>
      </div>
    </ProposalLayout>
  )
}

export default Documentation
